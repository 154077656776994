import React, { useState, useEffect, useRef } from 'react'; 
import aboutImg1 from '../src/assets/staticImages/about-1.jpg';
import aboutImg2 from '../src/assets/staticImages/about-2.jpg';
import aboutImg3 from '../src/assets/staticImages/about-3.jpg';
import aboutImg4 from '../src/assets/staticImages/about-4.jpg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import indianFlag from '../src/assets/staticImages/indian-flag.jpg';

function About() {
     

    return (
        <div style={{ fontFamily: 'Nunito' }} id="about" >
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-100" src={aboutImg1} style={{ width: '50%', height: '90%' }} alt="About 1" />
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-75" src={aboutImg2} alt="About 2" />
                                </div>
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-75" src={aboutImg3} style={{ width: '120%', height: '120%' }} alt="About 3" />
                                </div>
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-100" src={aboutImg4} style={{ width: '100%', height: '100%' }} alt="About 4" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h5 className="orange-heading">About Us</h5>
                            <h1 className="mb-4 black-heading indian-flag-text">
                                Savor the taste of India<img src={indianFlag} className='flag-css' alt="Indian Flag" />
                            </h1>
                            <p className="mb-4">
                            Experience an extraordinary culinary journey with the renowned Indian celebrity’s chef Ravindra. With over seventeen years of experience in 5- star and 7- star hotels and restaurants across the world, he has garnered remarkable success.
                            Chef Ravindra  is thrilled to bring his expertise to your table. Specializing in pure vegetarian cuisine, he masterfully captures the essence of Indian flavors that have delighted taste buds worldwide.
                              </p>
                            <p className="mb-4">
                            Chef Ravindra's illustrious career includes serving as the personal chef to Bollywood legend Amitabh Bachchan and showcasing his culinary prowess at the prestigious IIFA Awards in Macau in 2009, where his talents were celebrated and revered.
                                                        </p>
                            <p className="mb-4">
                            Don't miss this rare opportunity to indulge in the authentic flavors  "Jaika" of Indian right here. Join us for an unforgettable dining  experience that will transport you to the heart of India!
                            </p>
                            <p className="mb-4">

                            🙏Many many Thanks 🙏
                            <br/>
                            {/* 💐Warm Regards 💐 */}
                            <br/>

                            💐Team  Ishaan Pure Veg💐
                            </p>

                            <div className="row g-4 mb-4">
                                <div className="col-sm-6">
                                    <div className="d-flex align-items-center border-start  border-5 px-3">
                                        <h1 className="flex-shrink-0 display-5 mb-0" style={{fontWeight:'bold'}}>17+</h1>
                                        <div className="ps-4">
                                            <p className="mb-0">Years of</p>
                                            <h6 className="text-uppercase mb-0" style={{ fontWeight: 'bold' }}>Experience</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex align-items-center border-start border-5 px-3">
                                        <h1 className="flex-shrink-0 display-5  mb-0" style={{fontWeight:'bold'}}>70+</h1>
                                        <div className="ps-4">
                                            <p className="mb-0">Popular</p>
                                            <h6 className="text-uppercase mb-0" style={{ fontWeight: 'bold' }}>Indian Dishes</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AnchorLink className="btn btn-warning py-3 px-5 mt-2" href="#contact" style={{backgroundColor:'#E85C0D',color:'#fff',fontWeight:'bold'}}>Contact Now</AnchorLink>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export default About;
